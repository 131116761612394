import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const StationStationTemplate = ({
  data: { site, markdownRemark: station },
  location,
}) => {
  React.useEffect(() => {
    // addEventListener("resize", event => {})
    window.onmessage = e => {
      if (e.data.frameHeight) {
        let newHeight = e.data.frameHeight
        // setTimeout(function () {
        console.log("Changing iframe size", newHeight)
        document.getElementById("mfondemandiframe").style.height = `${
          newHeight + 50
        }px`
        // }, 2000)
      }
    }
  }, [])
  const siteTitle = site.siteMetadata?.title || `Title`
  const stationId = station.frontmatter?.stationId
    ? station.frontmatter?.stationId
    : null
  return (
    <Layout location={location} dark={true} title={siteTitle}>
      <div className={"min-h-screen w-full"}>
        <div
          className="station-station mx-auto"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h2
              itemProp="headline"
              className={`text-center article-title font-bold mb-12`}
            >
              {station.frontmatter.title}
            </h2>
          </header>
          <section
            className={`article-content ${stationId ? "mb-12" : "mb-32"}`}
            dangerouslySetInnerHTML={{ __html: station.html }}
            itemProp="articleBody"
          />
          {stationId && (
            <div className="w-full mb-36">
              <h2 className="font-bold mb-8 text-center text-4xl">
                Latest Episodes
              </h2>
              <iframe
                title="Ondemand example iframe"
                className="mr-iframe w-full"
                id="mfondemandiframe"
                src={`https://ondemandpage.myradio.click/?station=${stationId}&disableMore=true`}
              ></iframe>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({ data: { markdownRemark: station } }) => {
  return (
    <Seo
      title={station.frontmatter.title}
      description={station.frontmatter.description || station.excerpt}
    />
  )
}

export default StationStationTemplate

export const pageQuery = graphql`
  query StationStationBySlug(
    $id: String!
    $previousStationId: String
    $nextStationId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        stationId
      }
    }
    previous: markdownRemark(id: { eq: $previousStationId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextStationId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
